import { useEffect } from 'react'

import { BODY_IN_WEBVIEW_CLASSNAME } from 'shared/constants/dom'

import { detectSocialWebViewBrowser } from './useDeviceOs'

export const useDocumentSocialWebViewBrowser = () => {
  useEffect(() => {
    if (detectSocialWebViewBrowser()) {
      global?.document.body.classList.add(BODY_IN_WEBVIEW_CLASSNAME)
    }
  }, [])
}
