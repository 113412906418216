import { Base } from './Base'

export class PaymentApi extends Base {
  async init(payload: any) {
    return await this.apiClient.post('payment/paypal/init_transaction', payload)
  }

  async validate(payload: any) {
    return await this.apiClient.post('payment/validate', payload)
  }

  async getPaymentIntentWithSignature(payload: any) {
    return await this.apiClient.post('payment/form_merchant_data', payload)
  }
}
