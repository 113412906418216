/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * testaniaWebApi
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query'

import { useHttpClient } from '../../useHttpClient'

import type { ErrorType } from '../../useHttpClient'
import type { FlowConfig, GetGetExperimentConfigBranchExperimentParams } from '../model'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'

export const useGetGetBranchConfigBranchHook = () => {
  const getGetBranchConfigBranch = useHttpClient<FlowConfig>()

  return (branch: string, signal?: AbortSignal) => {
    return getGetBranchConfigBranch({ url: `/get-branch-config/${branch}`, method: 'get', signal })
  }
}

export const getGetGetBranchConfigBranchQueryKey = (branch: string) => {
  return [`/get-branch-config/${branch}`] as const
}

export const useGetGetBranchConfigBranchQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>,
  TError = ErrorType<unknown>
>(
  branch: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGetBranchConfigBranchQueryKey(branch)

  const getGetBranchConfigBranch = useGetGetBranchConfigBranchHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>
  > = ({ signal }) => getGetBranchConfigBranch(branch, signal)

  return { queryKey, queryFn, enabled: !!branch, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetGetBranchConfigBranchQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>
>
export type GetGetBranchConfigBranchQueryError = ErrorType<unknown>

export const useGetGetBranchConfigBranch = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>,
  TError = ErrorType<unknown>
>(
  branch: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGetBranchConfigBranchHook>>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGetBranchConfigBranchQueryOptions(branch, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useGetGetExperimentConfigBranchExperimentHook = () => {
  const getGetExperimentConfigBranchExperiment = useHttpClient<FlowConfig>()

  return (
    branch: string,
    experiment: string,
    params?: GetGetExperimentConfigBranchExperimentParams,
    signal?: AbortSignal
  ) => {
    return getGetExperimentConfigBranchExperiment({
      url: `/get-experiment-config/${branch}/${experiment}`,
      method: 'get',
      params,
      signal,
    })
  }
}

export const getGetGetExperimentConfigBranchExperimentQueryKey = (
  branch: string,
  experiment: string,
  params?: GetGetExperimentConfigBranchExperimentParams
) => {
  return [`/get-experiment-config/${branch}/${experiment}`, ...(params ? [params] : [])] as const
}

export const useGetGetExperimentConfigBranchExperimentQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>,
  TError = ErrorType<unknown>
>(
  branch: string,
  experiment: string,
  params?: GetGetExperimentConfigBranchExperimentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetGetExperimentConfigBranchExperimentQueryKey(branch, experiment, params)

  const getGetExperimentConfigBranchExperiment = useGetGetExperimentConfigBranchExperimentHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>
  > = ({ signal }) => getGetExperimentConfigBranchExperiment(branch, experiment, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(branch && experiment),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetGetExperimentConfigBranchExperimentQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>
>
export type GetGetExperimentConfigBranchExperimentQueryError = ErrorType<unknown>

export const useGetGetExperimentConfigBranchExperiment = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>,
  TError = ErrorType<unknown>
>(
  branch: string,
  experiment: string,
  params?: GetGetExperimentConfigBranchExperimentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGetExperimentConfigBranchExperimentHook>>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGetExperimentConfigBranchExperimentQueryOptions(
    branch,
    experiment,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
