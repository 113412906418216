import { PaymentSystemName, SolidPaymentFormEntity } from './types/payment'

export const REQUEST_HEADERS: any = {
  apiUrl: '',
  apiVersion: '',
  apiKey: '',
  token: '',
  language: 'en',
  sandbox: false,
}

export const PAY_PAL: PaymentSystemName = 'payPal'
export const BANK_CARD: PaymentSystemName = 'bankCard'

export const SOLID_SCRIPT_ID = 'solid-bank-card'
export const SOLID_SCRIPT_SRC = 'https://cdn.solidgate.com/js/solid-form.js'
export const SOLID_APPLE_PAY_BUTTON_ID = 'solid-apple-pay-button'
export const SOLID_BANK_CARD_BUTTON_ID = 'solid-payment-form-submit-button'
export const SOLID_PAY_PAL_BUTTON_ID = 'paypal-button'

export const SOLID_PAYMENT_FORM_ENTITIES: Array<SolidPaymentFormEntity> = ['applebtn', 'form']

export enum SOLID_ERROR_CODE {
  INSUFFICIENT_FUNDS = '3.02',
}
