import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAuthToken } from 'shared/providers/AuthTokenProvider'
import { getForerunnerApiKey, getForerunnerApiUrl } from 'shared/utils/env'

if (!process.env.APP_VERSION) {
  throw new Error('App version is required')
}

const axiosInstance = Axios.create({ baseURL: getForerunnerApiUrl() })

const DEFAULT_HEADERS = {
  'x-api-key': getForerunnerApiKey(),
}

export const useHttpClient = <ResponseData>() => {
  const { locale } = useIntl()
  const { token } = useAuthToken()

  return useCallback(
    (config: AxiosRequestConfig<unknown>) => {
      config.headers = {
        ...DEFAULT_HEADERS,
        'x-finalize-token': token,
        language: locale,
      }

      return axiosInstance({ ...config })
        .then(({ data, headers: { token } }) => {
          if (token) {
            data.token = token
          }

          return data as ResponseData
        })
        .catch()
    },
    [locale, token]
  )
}

export interface ErrorType<T = unknown> extends AxiosError<T> {
  error: string
}
