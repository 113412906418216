import { Systems } from './Interfaces/Systems'
import { AwsAnalytic } from './Systems/Amazon'
import { GTMDataLayer } from './Systems/GTMDataLayer'
import { HyrosDataLayer } from './Systems/HyrosDataLayer'

export const SYSTEMS: Systems = {
  amazon: AwsAnalytic,
  gtmDataLayer: GTMDataLayer,
  hyrosDataLayer: HyrosDataLayer,
}

export enum SYSTEMS_NAMES {
  amazon = 'amazon',
  gtmDataLayer = 'gtmDataLayer',
  hyrosDataLayer = 'hyrosDataLayer',
}

export enum ENV {
  develop = 'develop',
  stage = 'stage',
  prod = 'prod',
}
