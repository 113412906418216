import * as Sentry from '@sentry/nextjs'
import axios from 'axios'

import { isExceptionLogFnParams, Level, TransportFn } from 'libs/logger'

type Primitive = number | string | boolean | bigint | symbol | null | undefined

export const LOG_LEVEL_TO_SEVERITY_MAP: Record<Level, Sentry.SeverityLevel | undefined> = {
  silent: undefined,
  fatal: 'fatal',
  error: 'error',
  warn: 'warning',
  info: 'info',
  debug: 'debug',
  trace: 'debug',
}

const EVENTS_TO_SEND: string[] = []

const getDefaultTags = (): Record<string, Primitive> => {
  const defaultTags: Record<string, Primitive> = {}

  try {
    defaultTags['app.ab_test_name'] = global?.window?.localStorage?.getItem('testania_name') || ''
  } catch {}

  return defaultTags
}

const getTagsFromParams = (paramsList: unknown[]): Record<string, Primitive> => {
  for (const param of paramsList) {
    if (param && typeof param === 'object') {
      const { tags } = param as { tags: unknown }

      if (tags && typeof tags === 'object') {
        // FIXME: add better typing with checks for primitive value
        return tags as Record<string, Primitive>
      }
    }
  }

  return {}
}

const getTagsByAxiosError = (error: Error): Record<string, Primitive> => {
  const axiosError = axios.isAxiosError(error)
    ? error
    : axios.isAxiosError(error.cause)
    ? error.cause
    : null

  if (axiosError) {
    return {
      'api.status': axiosError.response?.status || 999,
      'api.code': axiosError.code,
      'api.url': axiosError.config.url,
      'api.base_url': axiosError.config.baseURL,
    }
  }

  return {}
}

export const transport: TransportFn = (params) => {
  const { level, levelsConfig, methodFnArgs, isLevelEnabled } = params
  const { value: levelValue } = levelsConfig[level]

  const eventName = typeof methodFnArgs?.[0] === 'string' ? methodFnArgs[0] : null

  // capture all logs included in EVENTS_TO_SEND as exceptions by sentry
  if (eventName && EVENTS_TO_SEND.includes(eventName)) {
    const tags = {
      // add tags from logger arguments
      ...getTagsFromParams(methodFnArgs),
      // add common tags for all errors
      ...getDefaultTags(),
    }

    Sentry.withScope((scope) => {
      try {
        for (const tagName of Object.keys(tags)) {
          scope.setTag(tagName, tags[tagName])
        }

        Sentry.captureException(new Error(eventName))
      } catch (err) {
        Sentry.captureException(err)
        Sentry.captureMessage('transports/sentry: catch block')
      }
    })

    return
  }

  // capture all logs above 'warn' level as exceptions by sentry
  if (levelValue > levelsConfig.warn.value && isExceptionLogFnParams(params)) {
    const { error } = params

    const tags = {
      // add tags from logger arguments
      ...getTagsFromParams(methodFnArgs),
      // add common tags for all axios request errors
      ...getTagsByAxiosError(error),
      // add common tags for all errors
      ...getDefaultTags(),
    }

    Sentry.withScope((scope) => {
      try {
        for (const tagName of Object.keys(tags)) {
          scope.setTag(tagName, tags[tagName])
        }

        Sentry.captureException(error ? error : new Error(methodFnArgs.join(', ')))
      } catch (err) {
        Sentry.captureException(err)
        Sentry.captureMessage('transports/sentry: catch block 2')
      }
    })

    return
  }

  // add logs for disabled levels and below to sentry breadcrumbs
  if (!isLevelEnabled) {
    return Sentry.addBreadcrumb({
      type: 'debug',
      category: 'console',
      message: methodFnArgs.join(', '),
      level: LOG_LEVEL_TO_SEVERITY_MAP[level],
      data: { arguments: methodFnArgs, logger: 'console' },
    })
  }
}
