import React, { createContext, useContext, useEffect, useMemo, ReactNode } from 'react'
import { useIntl } from 'react-intl'

import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { getAppEnv, getForerunnerApiKey, getForerunnerApiUrl } from 'shared/utils/env'
import { AppEnv } from 'shared/utils/env.types'

import { SolidPaymentAdapter } from './utils/SolidPaymentAdapter'

const solidPaymentAdapter = new SolidPaymentAdapter()

interface PaymentContextInterface {
  isReady: boolean
  solidPaymentAdapter: SolidPaymentAdapter
  initSolidBankCard: typeof solidPaymentAdapter.initSolidBankCard
  initSolidPaypal: typeof solidPaymentAdapter.initSolidPaypal
  clearAllHandlers: typeof solidPaymentAdapter.clearAllHandlers
  clearPaypalHandlers: typeof solidPaymentAdapter.clearPaypalHandlers
  clearBankCardHandlers: typeof solidPaymentAdapter.clearBankCardHandlers
}

const PaymentContext = createContext<PaymentContextInterface | null>(null)

function usePayment() {
  const context = useContext(PaymentContext)

  if (!context) {
    throw new Error(`usePayment must be used within PaymentProvider`)
  }

  return context
}

interface PaymentProviderProps {
  children?: ReactNode
}

const PaymentProvider = ({ children }: PaymentProviderProps): JSX.Element => {
  const intl = useIntl()
  const {
    models: { user },
  } = useAuthUser()

  useEffect(() => {
    const apiUrl = getForerunnerApiUrl()
    const apiKey = getForerunnerApiKey()
    const appEnv = getAppEnv()

    solidPaymentAdapter.solid.setHeaders({
      apiUrl: apiUrl,
      apiKey: apiKey,
      language: intl.locale,
      sandbox: appEnv !== AppEnv.PROD,
    })
  }, [intl.locale])

  // provide api to components
  const api = useMemo<PaymentContextInterface>((): PaymentContextInterface => {
    return {
      isReady: Boolean(user),
      solidPaymentAdapter,
      initSolidBankCard: solidPaymentAdapter.initSolidBankCard,
      initSolidPaypal: solidPaymentAdapter.initSolidPaypal,
      clearAllHandlers: solidPaymentAdapter.clearAllHandlers,
      clearPaypalHandlers: solidPaymentAdapter.clearPaypalHandlers,
      clearBankCardHandlers: solidPaymentAdapter.clearBankCardHandlers,
    }
  }, [user])

  return <PaymentContext.Provider value={api}>{children}</PaymentContext.Provider>
}

export { PaymentProvider, usePayment }
