import { PaymentApi } from './Payment'
import { ApiClient } from './apiClient'

export function apiFactory() {
  const api = new ApiClient()

  return {
    apiClient: api,
    payment: new PaymentApi({ apiClient: api }),
  }
}
