import { Config } from '../Interfaces/Config'

export abstract class AbstractSystem {
  public name: string
  public isInit: boolean

  constructor(name: string, isInit: boolean) {
    this.name = name
    this.isInit = isInit
  }

  abstract init({ id, config }: { id: string; config?: Config }): void
  abstract track(eventName: string, eventJson: object): void
  abstract purchase(event_name: string, data: object): void

  protected getName = () => {
    return this.name
  }

  protected getIsInit = () => {
    return this.isInit
  }
}
