import { WeightUnit } from 'shared/providers/ClientDataProvider'

import { roundTo } from './numbers'

import { DEFAULT_WEIGHT_IN_KG } from '../constants'

export const KG_TO_LBS_COEF = 2.20462
export const IN_TO_CM_COEF = 2.54

type WeightUnitType = 'kg' | 'lbs'

interface ConvertWeightParams {
  value: string | number
  fromUnit: WeightUnitType
  toUnit: WeightUnitType
  places?: number
}

export const convertWeight = ({ value, fromUnit, toUnit, places }: ConvertWeightParams) => {
  if (fromUnit === toUnit) {
    return roundTo(Number(value), places)
  }

  if (fromUnit === WeightUnit.KG) {
    return roundTo(Number(value) * KG_TO_LBS_COEF, places)
  }

  return roundTo(Number(value) / KG_TO_LBS_COEF, places)
}

export const convertToLb = (value: number = DEFAULT_WEIGHT_IN_KG) =>
  convertWeight({ value: value, fromUnit: WeightUnit.KG, toUnit: WeightUnit.LB, places: 1 })

type HeightUnitType = 'in' | 'cm'

interface ConvertHeightParams {
  value: string | number
  fromUnit: HeightUnitType
  toUnit: HeightUnitType
  places?: number
}

export const convertHeight = ({ value, fromUnit, toUnit, places }: ConvertHeightParams) => {
  if (fromUnit === toUnit) {
    return roundTo(Number(value), places)
  }

  if (fromUnit === 'in') {
    return roundTo(Number(value) * IN_TO_CM_COEF, places)
  }

  return roundTo(Number(value) / IN_TO_CM_COEF, places)
}
