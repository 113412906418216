import { SOLID_PAYMENT_FORM_ENTITIES } from 'shared/providers/PaymentProvider/solid-payment/constants'
import { SolidPaymentFormEntity } from 'shared/providers/PaymentProvider/solid-payment/types/payment'

import {
  DAYS_TO_SUBSCRIPTION_PERIOD_MAP,
  PAYMENT_METHOD,
  PAYMENT_METHOD_NAME,
  PERIOD_UNIT,
  SOLID_PAYMENT_FORM_ENTITY_TO_PAYMENT_METHOD_MAP,
} from 'features/payment/shared/constants'

export const getSubscriptionPeriodByDays = (
  days: number
): { periodCount: number; periodUnit: PERIOD_UNIT } => {
  return {
    periodCount: DAYS_TO_SUBSCRIPTION_PERIOD_MAP[days].count,
    periodUnit: DAYS_TO_SUBSCRIPTION_PERIOD_MAP[days].unit,
  }
}

export const getPaymentNameByMethod = (paymentMethod: PAYMENT_METHOD) => {
  return PAYMENT_METHOD_NAME[paymentMethod]
}

export const getPaymentMethodFromEvent = (eventData: unknown): PAYMENT_METHOD | null => {
  const entity = getSolidPaymentFormEntityFromEvent(eventData)

  if (!entity) {
    return null
  }

  return SOLID_PAYMENT_FORM_ENTITY_TO_PAYMENT_METHOD_MAP[entity] || null
}

export const getSolidPaymentFormEntityFromEvent = (
  eventData: unknown
): SolidPaymentFormEntity | null => {
  const { entity } = eventData as { entity: SolidPaymentFormEntity }

  return SOLID_PAYMENT_FORM_ENTITIES.includes(entity) ? entity : null
}
