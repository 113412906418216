import Router from 'next/router'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { useEventHandler } from 'shared/hooks/useEventHandler'

import { useLocalization } from 'shared/providers/LocalizationProvider'
import { useSplashScreen } from 'shared/providers/SplashScreenProvider'
import { logger } from 'shared/utils/logger'

function useManageSplashScreen() {
  const [isNavFinished, setIsNavFinished] = useState(false)

  const {
    models: { isReady },
  } = useLocalization()

  const { setVisible } = useSplashScreen()
  const clearTimer = useLogSplashScreenLags({
    isNavFinished: isNavFinished,
    setIsNavFinished: setIsNavFinished,
    isLocalizationReady: isReady,
  })

  useEffect(() => {
    const handleRouteChange = () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
      setIsNavFinished(true)
      clearTimer()
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [clearTimer])

  useEffect(() => {
    if (isNavFinished && isReady) {
      setVisible(false)
    }
  }, [isNavFinished, isReady, setVisible])
}

const NAVIGATION_COMPLETE_TIMEOUT_MS = 1000 * 10

interface UseLogSplashScreenLagsParams {
  isNavFinished: boolean
  setIsNavFinished: Dispatch<SetStateAction<boolean>>
  isLocalizationReady: boolean
}

const useLogSplashScreenLags = ({
  isNavFinished,
  setIsNavFinished,
  isLocalizationReady,
}: UseLogSplashScreenLagsParams) => {
  const timerIdRef = useRef<NodeJS.Timeout | null>(null)

  const getErrorTags = useEventHandler(() => ({
    is_nav_finished: isNavFinished,
    is_localization_ready: isLocalizationReady,
  }))

  useEffect(() => {
    // There are cases when routeChangeComplete event is not fired (idk why)
    // and SplashScreen does not hide itself.
    // Here we try to catch such cases and log them
    // to determine how many users experience this issue
    if (isNavFinished) return () => {}

    timerIdRef.current = setTimeout(() => {
      const tags = getErrorTags()

      logger.error(
        new Error(
          `SplashScreen is not hidden within ${NAVIGATION_COMPLETE_TIMEOUT_MS} milliseconds`
        ),
        { tags }
      )

      setIsNavFinished(true)
    }, NAVIGATION_COMPLETE_TIMEOUT_MS)

    return () => timerIdRef.current && clearTimeout(timerIdRef.current)
  }, [getErrorTags, isNavFinished, setIsNavFinished])

  return useCallback(() => timerIdRef.current && clearTimeout(timerIdRef.current), [])
}

export { useManageSplashScreen }
