import { Solid } from 'shared/providers/PaymentProvider/solid-payment'
import { SOLID_APPLE_PAY_BUTTON_ID } from 'shared/providers/PaymentProvider/solid-payment/constants'
import { PaymentSystemName } from 'shared/providers/PaymentProvider/solid-payment/types/payment'
import { OrderDetails, OrderHandlers } from 'shared/providers/PaymentProvider/types'
import {
  getPaymentMethodFromEvent,
  getSolidPaymentFormEntityFromEvent,
} from 'shared/providers/PaymentProvider/utils/helpers'
import { getForce3dsFlag } from 'shared/utils/devtools'
import { logger } from 'shared/utils/logger'

import { MAX_COUNT_ERROR_HANDLER, PAYMENT_METHOD } from 'features/payment/shared/constants'

import { getStyles } from './styles'

const removeSolidIframe = () => {
  const element: HTMLElement | null = document.getElementById('solid-payment-form-container')
  try {
    while (element?.firstChild) {
      element?.removeChild(element.firstChild)
    }
  } catch (e) {
    logger.error(Error('Error while removing solid iframe', { cause: e }))
  }
}

interface ReinitOnFailDecider {
  (orderDetails: OrderDetails, error: unknown): number
}

interface InitSolidOptions {
  /**
   *  The return value of the function represents the timeout in milliseconds
   *  before reinitializing the solid object after fail.
   */
  reinitOnFailDecider?: ReinitOnFailDecider
}

export interface InitSolidBankCardArgs {
  orderDetails: OrderDetails
  orderHandlers: OrderHandlers
  formMessages?: {
    submitButtonText?: string
    titleText?: string
    applePayMerchantName?: string
  }
  options?: InitSolidOptions
  language?: string
}

export interface InitSolidPaypalArgs {
  orderDetails: OrderDetails
  orderHandlers: OrderHandlers
  options?: InitSolidOptions
}

const defaultReinitOnFailDecider = () => 5000

export class SolidPaymentAdapter {
  public solid: typeof Solid
  public countErrorSolidBankCard = 0

  constructor() {
    this.solid = Solid
  }

  initSolidBankCard = async (params: InitSolidBankCardArgs) => {
    const { orderDetails, orderHandlers, formMessages, options, language = 'en' } = params
    const reinitOnFailDecider = options?.reinitOnFailDecider ?? defaultReinitOnFailDecider
    const { paymentToken, currency, ltv, id, paymentType, price, subscriptionPeriod, trialPeriod } =
      orderDetails

    removeSolidIframe()

    // @ts-expect-error
    const validatePayment = async (payment: PaymentSystemName, data) => {
      logger.debug('data', data)
      const meta = {
        payment_method: PAYMENT_METHOD.BANK_CARD, // from payment option
        payment_id: data.order.subscription_id || data.order.order_id, // from success data
        payment_type: paymentType,
        subscription_period: subscriptionPeriod, // from product
        subscription_trial_period: trialPeriod, // from product
        payment_token: paymentToken, // from sign-up response
      }
      logger.debug('validate payment, and meta', payment, meta)

      let response: Awaited<ReturnType<typeof this.solid.validate>>

      try {
        response = await this.solid?.validate(payment, meta)
      } catch (err) {
        let tags = {
          'app.feature': 'payments',
          'payment.status': 'validation',
          'payment.method_type': 'card',
          'payment.processor': 'solid',
        }

        logger.error(Error(`Payments.validation.error`, { cause: err }), {
          tags,
        })

        throw new Error('Payment validation error')
      }

      logger.debug('validate response', response)

      orderHandlers.onSuccess?.({
        orderDetails,
        payment: data,
        amount: response?.amount,
        cardBrand: response?.card_brand || data.brand,
        paymentMethod: getPaymentMethodFromEvent(data) || PAYMENT_METHOD.BANK_CARD,
        ltv,
      })
    }

    let applePayButtonParams: Record<string, boolean | string> = {
      enabled: true,
    }

    if (applePayButtonParams.enabled) {
      applePayButtonParams.containerId = SOLID_APPLE_PAY_BUTTON_ID
    }

    const force3ds = getForce3dsFlag()

    const initPaymentPayload = {
      // from checkout details
      id,
      price,
      paymentToken,
      currency,
      force3ds,
      payment_type: paymentType,
      language,

      // by default
      payment_method: PAYMENT_METHOD.BANK_CARD,
      // @ts-expect-error
      successHandler: (data) => {
        logger.debug('successHandler:', { data, orderDetails })
        validatePayment('bankCard', data)

        orderHandlers.onValidate?.(orderDetails)
      },
      // @ts-expect-error
      submitHandler: (data) => {
        logger.debug('submitHandler:', { data, orderDetails })

        const paymentMethod = getPaymentMethodFromEvent(data) || PAYMENT_METHOD.BANK_CARD

        orderHandlers.onSubmit?.(orderDetails, paymentMethod)
      },
      // @ts-expect-error
      errorHandler: (data) => {
        this.countErrorSolidBankCard += 1
        logger.debug('[bc] errorHandler:', { data, orderDetails })

        const tags = {
          'app.feature': 'payments',
          'payment.status': 'pending',
          'payment.method_type': 'card',
          'payment.processor': 'solid',
          'payment.entity': 'form',
        }

        logger.error(Error(`Payments.paymentForm.errorHandler.error`), {
          tags,
        })

        orderHandlers.onError?.(orderDetails, data)

        if (this.countErrorSolidBankCard <= MAX_COUNT_ERROR_HANDLER) {
          return
        }

        setTimeout(() => {
          this.initSolidBankCard(params)
        }, 3000)
      },
      // @ts-expect-error
      readyHandler: (data) => {
        logger.debug('readyHandler', { data, orderDetails })
        const entity = getSolidPaymentFormEntityFromEvent(data)

        orderHandlers.onReady?.(orderDetails, entity)
      },
      // @ts-expect-error
      failHandler: (data) => {
        logger.debug('[bc] failHandler:', { data, orderDetails })

        const tags = {
          'app.feature': 'payments',
          'payment.status': 'processed',
          'payment.method_type': 'card',
          'payment.processor': 'solid',
          'payment.entity': data?.entity,
          'payment.error_code': data?.code || 'unknown',
        }

        logger.error(Error(`Payments.paymentForm.failHandler.error`), {
          tags,
        })

        orderHandlers.onFail?.(orderDetails, data)

        const timeoutInMs = reinitOnFailDecider(orderDetails, data)

        if (timeoutInMs >= 0) {
          setTimeout(() => {
            this.initSolidBankCard(params)
          }, timeoutInMs)
        }
      },
      processingHandler: () => {
        logger.debug('processingHandler', { orderDetails })

        orderHandlers.onProcessing?.(orderDetails)
      },
      enterCardInfoHandler: () => {
        orderHandlers.onEnterCardInfo?.()
      },
      formParams: {
        submitButtonText: formMessages?.submitButtonText || 'Continue',
        titleText: ' ',
        cardExpiryDateLabel: ' ',
        cardCvvLabel: ' ',
        cardNumberLabel: ' ',
        formTypeClass: 'default',
        allowSubmit: false,
        submitButtonId: '',
        autoFocus: false,
      },
      applePayButtonParams,
      styles: getStyles(),
      apple_pay_merchant_name: formMessages?.applePayMerchantName,
      is_trial: orderDetails.trialPeriod !== orderDetails.subscriptionPeriod,
    }

    orderHandlers.onInit?.(orderDetails)

    return this.solid?.init('bankCard', initPaymentPayload).catch((err) => {
      let tags = {
        'app.feature': 'payments',
        'payment.status': 'initialization',
        'payment.state': 'api-request-error',
        'payment.method_type': 'card',
        'payment.processor': 'solid',
      }

      logger.error(Error(`Payments.init.error`, { cause: err }), {
        tags,
      })
    })
  }

  initSolidPaypal = async (params: InitSolidPaypalArgs) => {
    const { orderDetails, orderHandlers, options } = params
    const reinitOnFailDecider = options?.reinitOnFailDecider ?? defaultReinitOnFailDecider
    const { currency, paymentToken, ltv, id, paymentType, price, subscriptionPeriod, trialPeriod } =
      orderDetails

    // @ts-expect-error
    const validatePayment = async (payment: PaymentSystemName, data) => {
      logger.debug('data', data)

      const meta = {
        payment_method: PAYMENT_METHOD.PAYPAL, // from payment option
        payment_id: data.order?.subscription_id || data.order?.order_id, // from success data
        payment_type: paymentType,
        subscription_period: subscriptionPeriod, // from product
        subscription_trial_period: trialPeriod, // from product
        payment_token: paymentToken, // from sign-up response
      }
      logger.debug('validate payment, and meta', payment, meta)

      let response: Awaited<ReturnType<typeof this.solid.validate>>

      try {
        response = await this.solid?.validate(payment, meta)
      } catch (err) {
        let tags = {
          'app.feature': 'payments',
          'payment.status': 'validation',
          'payment.method_type': 'paypal',
          'payment.processor': 'solid',
        }

        logger.error(Error(`Payments.validation.error`, { cause: err }), {
          tags,
        })

        throw new Error('Payment validation error')
      }

      logger.debug('validate response', response)

      orderHandlers.onSuccess?.({
        orderDetails,
        payment: data,
        amount: response?.amount,
        cardBrand: response?.card_brand || '',
        paymentMethod: PAYMENT_METHOD.PAYPAL,
        ltv,
      })
    }

    const initPaymentPayload = {
      // from checkout details
      id,
      price,
      currency,
      paymentToken,
      payment_type: paymentType,

      // by default
      payment_method: PAYMENT_METHOD.PAYPAL,

      onClickHandler: (e: any) => {
        logger.debug('[pp-btn-processing]', e?.detail)

        orderHandlers.onProcessing?.(orderDetails)
      },

      errorHandler: (e: any) => {
        logger.debug('[pp-btn-error] PayPal error', e?.detail)

        const tags = {
          'app.feature': 'payments',
          'payment.status': 'pending',
          'payment.method_type': 'paypal',
          'payment.processor': 'solid',
          entity: 'paypalbtn',
        }

        logger.error(Error(`Payments.paypalbtn.error`, { cause: e?.detail?.error }), {
          tags,
        })

        orderHandlers.onError?.(orderDetails, e)
      },

      readyHandler: (e: any) => {
        logger.debug('[pp-btn-ready] Done:', e?.detail)

        orderHandlers.onReady?.(orderDetails)
      },
      // @ts-expect-error
      successHandler: (data) => {
        if (data?.error?.code) {
          // @ts-expect-error
          if (Array.isArray(global.window?.sa?.q)) {
            // @ts-expect-error
            const solidEventsList = global.window?.sa?.q as Array<unknown>
            const lastEvent = solidEventsList[solidEventsList.length - 1]
            logger.debug('[pp-btn-success] Last solid event: ', lastEvent)
          }

          logger.debug('[pp-btn-success] Error: ', data.error)

          const tags = {
            'app.feature': 'payments',
            'payment.status': 'processed',
            'payment.method_type': 'paypal',
            'payment.processor': 'solid',
            'payment.entity': 'paypalbtn',
            'payment.error_code': data?.error?.code || 'unknown',
          }

          logger.error(Error(`Payments.paypalbtn.error`, { cause: data?.error }), {
            tags,
          })

          orderHandlers.onFail?.(orderDetails, data?.error)

          const timeoutInMs = reinitOnFailDecider(orderDetails, data)

          if (timeoutInMs >= 0) {
            setTimeout(() => {
              this.initSolidPaypal(params)
            }, timeoutInMs)
          }
        } else {
          logger.debug('[pp-btn-success] Done: ', data)
          validatePayment('payPal', data)

          orderHandlers.onValidate?.(orderDetails)
        }
      },
    }

    return this.solid?.init('payPal', initPaymentPayload).catch((err) => {
      let tags = {
        'app.feature': 'payments',
        'payment.status': 'initialization',
        'payment.state': 'api-request-error',
        'payment.method_type': 'paypal',
        'payment.processor': 'solid',
      }

      logger.error(Error(`Payments.init.error`, { cause: err }), {
        tags,
      })
    })
  }

  clearAllHandlers = () => {
    this.solid?.clearAllHandlers('payPal')
    this.solid?.clearAllHandlers('bankCard')
  }

  clearPaypalHandlers = () => {
    this.solid?.clearAllHandlers('payPal')
  }

  clearBankCardHandlers = () => {
    this.solid?.clearAllHandlers('bankCard')
  }
}
