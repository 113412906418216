//@TODO: rename UtilsNew to Utils after refactoring utils.js
import Cookies from 'js-cookie'
import UAParser from 'ua-parser-js'

import {
  AB_TEST_NAME_COOKIE_NAME,
  COUNTRY_COOKIE_NAME,
  UUID_COOKIE_NAME,
} from 'shared/providers/FlowLoader/constants'
import { saveCookieValue } from 'shared/providers/FlowLoader/helpers'
import { getUrlParams } from 'shared/utils/url-params'

import { urlParams } from '../Interfaces/DataProvider'
import { DeviceType, StringDictionary } from '../types'

export class UtilsNew {
  public static uuidLocalStorageKey: string = 'uuid'
  public static userIdLocalStorageKey: string = 'userId'
  public static countryLocalStorageKey: string = 'country'
  public static uaParser: UAParser = new UAParser()
  public static country: string = ''

  public static async getAsyncUrlParams(asyncGetUrl: () => any): Promise<urlParams> {
    const url = await asyncGetUrl()

    try {
      return JSON.parse(url)
    } catch (e) {
      return {
        data: 'empty_url',
        'error:': JSON.stringify(e),
      }
    }
  }

  public static getUuid(): string {
    let uuid = localStorage?.getItem(this.uuidLocalStorageKey) || window?.attr_uuid

    if (uuid) {
      return uuid
    }

    const uuidFromCookie = Cookies.get(UUID_COOKIE_NAME)

    if (uuidFromCookie) {
      return uuidFromCookie
    }

    const newUuid =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    localStorage?.setItem(this.uuidLocalStorageKey, newUuid)
    window && (window.attr_uuid = newUuid)
    saveCookieValue(UUID_COOKIE_NAME, newUuid)

    return newUuid
  }

  public static parseUserAgentDevice(userAgent: string): any {
    return this.uaParser.setUA(userAgent).getDevice()
  }

  public static getCountry() {
    const countryFromCookie = Cookies.get(COUNTRY_COOKIE_NAME)
    const countryFromStorage = localStorage?.getItem(this.countryLocalStorageKey)

    return this.country || countryFromCookie || countryFromStorage || null
  }

  public static setCountry(country: string) {
    this.country = country
    localStorage?.setItem(this.countryLocalStorageKey, country)
  }

  public static getAbTestName() {
    const abTestName = localStorage?.getItem('testania_name') || window?.attr_testania_name

    if (!abTestName) {
      return Cookies.get(AB_TEST_NAME_COOKIE_NAME) || null
    }

    return abTestName
  }

  public static getCookies(): object {
    const reducer = (accumulator: StringDictionary, current: string) => {
      const [name, value] = current.split('=')
      accumulator[name] = value

      return accumulator
    }

    return document.cookie.split('; ').reduce(reducer, {})
  }

  public static getDeviceType(): DeviceType {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }

    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }

    return 'desktop'
  }

  public static getUserId(): string | null {
    return localStorage?.getItem(this.userIdLocalStorageKey)
      ? localStorage?.getItem(this.userIdLocalStorageKey)
      : null
  }

  public static setUserId(value: string) {
    return localStorage?.setItem(this.userIdLocalStorageKey, value)
  }

  // Find another way to get this info
  public static getBrowserInfo(): object {
    let myModule = {
      options: [],
      header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        // @ts-expect-error
        window.opera,
      ],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],
      init: function () {
        let agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser)

        return { os: os, browser: browser }
      },
      matchItem: function (string: any, data: any) {
        let i, j, regex, regexv, match, matches, version

        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i')
          match = regex.test(string)
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
            matches = string.match(regexv)
            version = ''
            if (matches) {
              if (matches[1]) {
                matches = matches[1]
              }
            }

            if (matches) {
              matches = matches.split(/[._]+/)
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.'
                } else {
                  version += matches[j]
                }
              }
            } else {
              version = '0'
            }

            return {
              name: data[i].name,
              version: parseFloat(version),
            }
          }
        }

        return { name: 'unknown', version: 0 }
      },
    }

    let e = myModule.init()

    return {
      'os.name': e.os.name,
      'os.version': e.os.version,
      'browser.name': e.browser.name,
      'browser.version': e.browser.version,
    }
  }

  public static getNavigatorLanguage = () => {
    let language

    if (navigator?.languages?.length) {
      language = navigator.languages[0]
    } else {
      language =
        // @ts-expect-error
        navigator?.userLanguage ||
        navigator.language ||
        // @ts-expect-error
        navigator?.browserLanguage ||
        'default_browser_lang'
    }

    return language
  }

  public static normalizeEventLabelFormat = (data: any) => {
    if (data?.event_label) {
      if (typeof data.event_label === 'string') {
        return {
          ...data,
          event_label: UtilsNew.normalizeLabel(data.event_label),
        }
      } else if (Array.isArray(data.event_label)) {
        const newEventLabel: Array<string> = []

        data.event_label.forEach((element: string) => {
          newEventLabel.push(UtilsNew.normalizeLabel(element))
        })

        return {
          ...data,
          event_label: newEventLabel,
        }
      }
    }

    return data
  }

  static normalizeLabel = (value: string) =>
    value
      .replace(/\s+/g, '_')
      .replace(/-/g, '_')
      .replace(/[^\w\s-]/gi, '')
      .toLowerCase()

  public static getDefaultRecordData(eventName: string = 'purchase') {
    const urlParams = getUrlParams({ parsed: true })

    return {
      event_type: eventName,
      host: window.location.hostname,
      url: window.location.pathname,
      fragment: window.location.pathname,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      lang: localStorage?.getItem('language') || 'default_lang',
      language: UtilsNew.getNavigatorLanguage(),
      agent: navigator.userAgent,
      device: UtilsNew.parseUserAgentDevice(navigator.userAgent).model,
      referrer: document.referrer,
      timestamp: Date.now(),
      browser_info: UtilsNew.getBrowserInfo(),
      device_type: UtilsNew.getDeviceType(),
      uuid: UtilsNew.getUuid(),
      user_id: UtilsNew.getUserId(),
      url_params: urlParams,
      country: UtilsNew.getCountry() || 'default_country',
      cookies: UtilsNew.getCookies(),
      gender: localStorage?.getItem('gender'),
      ab_test_name: UtilsNew.getAbTestName() || 'default_ab_test_name',
      content_name: urlParams['branch-name'],
    }
  }
}
