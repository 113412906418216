import { api } from '../apiSingleton'
import { InitPayload, ValidatePayment } from '../interfaces/payment'

export abstract class AbstractPayment {
  public name: string

  protected constructor(name: string) {
    this.name = name
  }

  protected abstract normalizeInitData(data: any): any

  public async initPayment(payload: InitPayload) {
    const normalizePayload = await this.normalizeInitData(payload)

    return await api.payment.init(normalizePayload)
  }

  public async validatePayment(
    payload: ValidatePayment
  ): Promise<{ amount: string; card_brand: string }> {
    return await api.payment.validate(payload)
  }

  public async isSupport(paymentType: string): Promise<boolean> {
    return paymentType === this.name
  }
}
