import Axios from 'axios'

import { getHyrosApiKey, getHyrosApiUrl } from 'shared/utils/env'

const DEFAULT_HEADERS = {
  'API-Key': getHyrosApiKey() || '',
  'Content-Type': 'application/json',
}

const axiosInstance = Axios.create({
  baseURL: getHyrosApiUrl(),
  headers: {
    ...DEFAULT_HEADERS,
  },
})

export const getHyrosHttpClient = () => axiosInstance
