import { useGetLocation } from 'shared/api/forerunner'

type Options = Parameters<typeof useGetLocation>[0] | any

export const useLocation = (options: Options = {}) => {
  const { data } = useGetLocation({
    ...options,
    query: { staleTime: Infinity, ...options?.query },
  })

  return { country: data?.country || '', ip: data?.ip_address || '' }
}
