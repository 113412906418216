import { WeightUnit, HeightUnit } from 'shared/providers/ClientDataProvider'
import { Range } from 'shared/utils/common/types'
import { convertToLb } from 'shared/utils/unit-converters'

export const DEFAULT_GENDER = 'female'
export const DEFAULT_WEIGHT_IN_KG = 65
export const DEFAULT_HEIGHT_IN_CM = 180
export const AGE_MAX_VALUE = 99
export const AGE_MIN_VALUE = 16

export const EMAIL_MAX_LENGTH = 250
export const PASSWORD_MIN_LENGTH = 6
export const PASSWORD_MAX_LENGTH = 1000
export const FIRST_NAME_MAX_LENGTH = 250
export const LAST_NAME_MAX_LENGTH = 250

export const VALID_WEIGHT_RANGES_MAP: Record<WeightUnit, Range> = {
  [WeightUnit.KG]: {
    from: 30,
    to: 400,
  },
  [WeightUnit.LB]: {
    from: Math.ceil(convertToLb(30)),
    to: Math.floor(convertToLb(400)),
  },
}

export const VALID_HEIGHT_RANGES_MAP: Record<HeightUnit, Range> = {
  [HeightUnit.IN]: {
    from: 26,
    to: 98,
  },
  [HeightUnit.CM]: {
    from: 65,
    to: 251,
  },
}
