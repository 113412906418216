import { logger } from 'shared/utils/logger'

export const setOptions = async (options: any, constans: any) => {
  return Object.assign(constans, options)
}

export const normalizeObject = async (normalizeExample: any, payload: any) => {
  let normalizeData = {}

  for await (const [key, value] of Object.entries(payload)) {
    normalizeExample.forEach((subKey: any) => {
      if (key === subKey) {
        normalizeData = {
          ...normalizeData,
          [key]: value,
        }
      } else if (key === 'id' && payload.payment_type === 'subscription') {
        normalizeData = {
          ...normalizeData,
          ['product_id']: value,
        }
      }
    })
  }

  return normalizeData
}

export const toUnderScore = (currentProduct: any) => {
  let newObject = {}
  const camelToUnderscore = (key: any) => {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }

  for (let camel in currentProduct) {
    // @ts-expect-error
    newObject[camelToUnderscore(camel)] = currentProduct[camel]
  }

  return newObject
}

interface LoadScriptParams {
  id?: string
  src: string
  fetchpriority?: 'low' | 'high' | 'auto'
  parentNode?: HTMLElement
  removeElementOnError?: boolean
}

export const loadScript = ({
  id = '',
  src,
  fetchpriority = 'auto',
  parentNode,
  removeElementOnError = false,
}: LoadScriptParams): Promise<void> => {
  return new Promise((resolve, reject) => {
    const node = parentNode ?? (document.head || document.getElementsByTagName('head')[0])
    const scriptEl = document.createElement('script')
    scriptEl.id = id
    scriptEl.type = 'text/javascript'
    scriptEl.src = src
    // @ts-expect-error
    scriptEl.fetchpriority = fetchpriority

    scriptEl.onload = () => resolve()
    scriptEl.onerror = (e) => {
      if (removeElementOnError) {
        scriptEl.remove()
      }

      reject(e)
    }

    node.appendChild(scriptEl)
  })
}

export const pasteSolidScript = async (params: LoadScriptParams): Promise<void> => {
  try {
    await loadScript(params)

    return Promise.resolve()
  } catch (error) {
    logger.warn(Error('Error while loading script', { cause: error }))
  }

  // try to load one more time
  return loadScript(params)
}
