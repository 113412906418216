import { ColorName } from 'ui-elements/themes/types'

export const getColorValue = (color: ColorName) => {
  if (typeof window === 'undefined') {
    return '#ffffff'
  }

  const value = getComputedStyle(window.document.body).getPropertyValue(`--${color}`)

  return normalizeColorValue(value)
}

export const getColor = (color: ColorName) => `var(--${color})`

export const normalizeColorValue = (val: string) => {
  if (val.length === 4) {
    return ['#', val[1], val[1], val[2], val[2], val[3], val[3]].join('')
  }

  return val
}
