import React from 'react'

import { SplashScreen } from 'ui-elements/SplashScreen'

interface SplashScreenContextInterface {
  visible: boolean
  setVisible: (value: boolean) => void
}

const SplashScreenContext = React.createContext<SplashScreenContextInterface | null>(null)

function useSplashScreen() {
  const context = React.useContext(SplashScreenContext)

  if (!context) {
    throw new Error(`useSplashScreen must be used within SplashScreenProvider`)
  }

  return context
}

interface SplashScreenProviderProps {
  /**
   * The child nodes SplashScreenProvider has wrapped
   */
  children?: React.ReactNode

  minAnimationDuration?: number
}

const SplashScreenProvider = ({
  children,
  minAnimationDuration = 500,
}: SplashScreenProviderProps) => {
  const [visible, setVisible] = React.useState(true)
  const [isMinAnimationDurationReached, setIsMinAnimationDurationReached] = React.useState(false)
  const [isTransitionFinished, setIsTransitionFinished] = React.useState(false)

  const onTransitionEnd = React.useCallback(() => {
    setIsTransitionFinished(true)
  }, [])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setIsMinAnimationDurationReached(true), minAnimationDuration)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [minAnimationDuration])

  const api = React.useMemo<SplashScreenContextInterface>(
    () => ({
      visible,
      setVisible,
    }),
    [visible]
  )

  return (
    <SplashScreenContext.Provider value={api}>
      {!isTransitionFinished && (
        <SplashScreen
          visible={visible || !isMinAnimationDurationReached}
          onTransitionEnd={onTransitionEnd}
        />
      )}
      {children}
    </SplashScreenContext.Provider>
  )
}

export { SplashScreenProvider, useSplashScreen }
