import { getHyrosHttpClient } from './useHttpClient'

export type CreateHyrosOrder = {
  email: string
  orderId: string
  orderDiscount: number
  currency: string
  productName: string
  productId: string
  price: number
  ipAddress: string
}

export const saveHyrosLayerOrder = ({
  currency,
  email,
  productName,
  productId,
  price,
  orderDiscount,
  orderId,
  ipAddress,
}: CreateHyrosOrder) => {
  const client = getHyrosHttpClient()

  const data = {
    currency,
    date: new Date().toISOString(),
    email,
    items: [
      {
        name: productName,
        price,
        externalId: productId,
        quantity: 1,
        itemDiscount: orderDiscount,
      },
    ],
    orderId,
    leadIps: [ipAddress],
  }

  return client.post('/orders', data)
}
