import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'

import { REQUEST_HEADERS } from 'shared/providers/PaymentProvider/solid-payment/constants'
import { logger } from 'shared/utils/logger'

export class ApiClient {
  private getApiUrl() {
    return REQUEST_HEADERS.apiUrl
  }

  private getLocalization() {
    return REQUEST_HEADERS.language
  }

  private getApiKey() {
    return REQUEST_HEADERS.apiKey
  }

  private getApiVersion() {
    return REQUEST_HEADERS.apiVersion
  }

  private getToken() {
    return REQUEST_HEADERS.token
  }

  private getClientVersion() {
    return REQUEST_HEADERS.clientVersion
  }

  async get(url: any, params: any) {
    return this.request({
      url,
      params,
      method: 'GET',
    })
  }

  async post(url: any, payload = {}) {
    return this.request({
      url,
      method: 'POST',
      data: payload,
    })
  }

  async put(url: any, payload = {}) {
    return this.request({
      url,
      method: 'PUT',
      data: payload,
    })
  }

  async patch(url: any, payload = {}) {
    return this.request({
      url,
      method: 'PATCH',
      data: payload,
    })
  }

  async delete(url: any, payload = {}) {
    return this.request({
      url,
      method: 'DELETE',
      data: payload,
    })
  }

  isSandbox() {
    return REQUEST_HEADERS.sandbox
  }

  async request(options: { url: any; params?: any; method?: string; data?: {} | {} | {} | {} }) {
    const tmpOptions = {
      headers: undefined,
      ...options,
      url: `/${options.url}`,
    }

    const axiosInstance = axios.create({ baseURL: this.getApiUrl() })

    axiosRetry(axiosInstance, {
      retries: 2,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error: unknown) =>
        axios.isAxiosError(error) &&
        (error.code === AxiosError.ERR_NETWORK || error.response?.status === 500),
    })

    this.setheaders(axiosInstance)

    if (!tmpOptions.params) {
      tmpOptions.params = {}
    }

    if (this.getToken()) {
      // @ts-expect-error
      tmpOptions.headers = {
        token: this.getToken(),
      }
    }

    //
    if (this.isSandbox()) {
      try {
        tmpOptions.headers = {
          // @ts-expect-error
          ...tmpOptions.headers,
          sandbox: true,
        }
      } catch (error) {
        logger.error(Error('Error setting sandbox header', { cause: error }))
      }
    }

    let response = {}

    try {
      response = await axiosInstance(tmpOptions)
      // @ts-expect-error
      if (response.headers.token) response.data.token = response.headers.token

      // @ts-expect-error
      return Promise.resolve(response.data)
    } catch (error) {
      const { config, response } = error as AxiosError

      logger.debug('apiClient: request data', options.data)
      logger.debug('apiClient: request headers', config.headers)
      logger.debug('apiClient: response data', response?.data)

      return Promise.reject(error)
    }
  }

  private setheaders(axiosInstance: any) {
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'

    axiosInstance.defaults.headers.common['x-api-key'] = this.getApiKey()
    axiosInstance.defaults.headers.common['language'] = this.getLocalization()
  }
}
