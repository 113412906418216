const SESSION_STORAGE_PREFIX = 'frr-'

export const setSessionStorageValue = (key: string, value: string) => {
  sessionStorage.setItem(`${SESSION_STORAGE_PREFIX}${key}`, value)
}

export const getSessionStorageValue = (key: string) => {
  return sessionStorage.getItem(`${SESSION_STORAGE_PREFIX}${key}`)
}

export const removeSessionStorageValue = (key: string) => {
  sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX}${key}`)
}
