import React from 'react'
import styled, { keyframes } from 'styled-components'

import { IconLogoDark } from '../Icons/IconLogoDark'

export interface SplashScreenProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean
}

const beatAnimation = () => keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.85);
  }
  100% {
    transform: scale(1);
  }
`

const StyledSplashScreen = styled.div<{ $visible?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.color('base-surface')};

  transition: opacity 0.2s cubic-bezier(0.32, 0, 0.67, 0);
  opacity: ${({ $visible }) => `${$visible ? '1' : '0'}`};
`

const Content = styled.div`
  animation: ${beatAnimation} 2s infinite;
  display: flex;
  justify-content: center;

  & svg {
    max-width: 60%;
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`

export const SplashScreen = React.memo(({ visible, ...rest }: SplashScreenProps) => {
  return (
    <StyledSplashScreen data-testid="splash-screen" $visible={visible} {...rest}>
      <Content>
        <IconLogoDark />
      </Content>
    </StyledSplashScreen>
  )
})
