import { detectDeviceOs } from 'shared/hooks/useDeviceOs'

import { BaseScreen, FlowConfig, PaymentScreen } from 'shared/api/testania'
import { screensConfig } from 'shared/config/screens'
import { ScreenId } from 'shared/config/types'
import { logger } from 'shared/utils/logger'

const doesScreenExist = (screen: BaseScreen | PaymentScreen) =>
  Boolean(screensConfig[screen.id as ScreenId])

const filterOnboardingFlow = (
  onboardingFlow: FlowConfig['onboarding_flow'],
  country = '',
  locale = ''
) => {
  return onboardingFlow.filter(
    ({ config: { country: countryList, locales: localeList, device } }) => {
      const countryCondition = countryList
        ? countryList.split(',').includes(country.toLowerCase())
        : true
      const localeCondition = localeList
        ? localeList.split(',').includes(locale.toLowerCase())
        : true
      const deviceCondition = device
        ? detectDeviceOs(global?.navigator?.userAgent) === device
        : true

      return countryCondition && localeCondition && deviceCondition
    }
  )
}

export const onLoad = (
  config: FlowConfig,
  country: string = '',
  locale: string = ''
): FlowConfig => {
  const isValid = config.payment_flow.every(doesScreenExist)

  if (!isValid) {
    logger.info('Screens config:', config)
    logger.error(Error('Screens config is not valid'))
  }

  return {
    ...config,
    onboarding_flow: filterOnboardingFlow(config.onboarding_flow, country, locale),
  }
}
