import { api } from 'shared/providers/PaymentProvider/solid-payment/apiSingleton'
import {
  BANK_CARD,
  SOLID_SCRIPT_ID,
  SOLID_SCRIPT_SRC,
} from 'shared/providers/PaymentProvider/solid-payment/constants'
import { pasteSolidScript } from 'shared/providers/PaymentProvider/solid-payment/helpers/utils'
import { InitBankCard } from 'shared/providers/PaymentProvider/solid-payment/interfaces/payment'
import { AbstractPayment } from 'shared/providers/PaymentProvider/solid-payment/services/abstractPayment'
import { logger } from 'shared/utils/logger'

export class BankCard extends AbstractPayment {
  private isHandlerActive: boolean
  private brandCard: string
  private solidForm: unknown
  private loadPaymentFormPromise: Promise<void> | null = null
  private unsubscribeFromSubmitButtonClick?: () => void

  constructor() {
    super(BANK_CARD)
    this.isHandlerActive = false
    this.brandCard = ''
    this.loadPaymentFormPromise = null
  }

  public async loadPaymentForm(): Promise<void> {
    if (this.loadPaymentFormPromise) {
      return this.loadPaymentFormPromise
    }

    /**
     * TODO: write universal implementation for loading scripts with retries
     */
    this.loadPaymentFormPromise = pasteSolidScript({
      id: SOLID_SCRIPT_ID,
      src: SOLID_SCRIPT_SRC,
      fetchpriority: 'high',
      removeElementOnError: true,
    })

    this.loadPaymentFormPromise.catch((err) => {
      const tags = {
        'app.feature': 'payments',
        'payment.status': 'initialization',
        'payment.state': 'sdk-loading-error',
        'payment.method_type': 'card',
        'payment.processor': 'solid',
      }

      logger.error(Error(`Payments.paymentForm.loadSdk.error`, { cause: err }), {
        tags,
      })

      this.loadPaymentFormPromise = null
    })
  }

  public async initPayment(payload: InitBankCard): Promise<any> {
    try {
      const normalizedData = await this.normalizeInitData(payload)
      const [{ merchant_data: merchantData }]: any = await Promise.all([
        api.payment.getPaymentIntentWithSignature({
          ...normalizedData,
        }),
        this.loadPaymentForm(),
      ])

      logger.debug('Merchant data', {
        merchantData: { order_id: merchantData.order_id, merchant: merchantData.merchant },
      })

      const data = {
        merchantData: {
          paymentIntent: merchantData.payment_intent,
          merchant: merchantData.merchant,
          signature: merchantData.signature,
        },
        styles: payload.styles,
        formParams: payload.formParams,
        applePayButtonParams: payload.applePayButtonParams,
      }

      this.clearAllHandlers()
      this.clearSubmitButtonClickHandler()

      // @ts-expect-error
      this.solidForm = PaymentFormSdk.init(data)

      if (this.solidForm) {
        this.addSubmitButtonClickHandler(this.solidForm)
      }

      if (!this.isHandlerActive) {
        this.addHandlers(this.solidForm, payload)
      }

      return merchantData
    } catch (e) {
      logger.warn('Error while init payment', e)

      return Promise.reject(e)
    }
  }

  protected async normalizeInitData(payload: any) {
    return {
      language: payload.language,
      payment_token: payload.paymentToken,
      selected_product: payload.id,
    }
  }

  public clearAllHandlers() {
    if (this.solidForm) {
      // @ts-expect-error
      this.solidForm.unsubscribeAll()
      this.clearSubmitButtonClickHandler()
      this.isHandlerActive = false
    }
  }

  private clearSubmitButtonClickHandler() {
    if (this.unsubscribeFromSubmitButtonClick) {
      this.unsubscribeFromSubmitButtonClick()
      this.unsubscribeFromSubmitButtonClick = undefined
    }
  }

  private addSubmitButtonClickHandler(form: any) {
    const submitButton = document.getElementById('solid-payment-form-submit-button')

    if (submitButton) {
      const handleClick = () => form?.submit()

      submitButton.addEventListener('click', handleClick)

      this.unsubscribeFromSubmitButtonClick = () => {
        submitButton.removeEventListener('click', handleClick)
      }
    }
  }

  private addHandlers(form: any, payload: InitBankCard) {
    form.on('mounted', (e: any) => {
      //Merchant's code
      payload.readyHandler(e?.data)
    })
    form.on('submit', (e: any) => {
      //Merchant's code
      payload.submitHandler(e?.data)
    })
    form.on('success', (e: any) => {
      // Event of the success payment processing.
      payload.successHandler({
        ...e?.data,
        brand: this.brandCard,
      })
    })
    form.on('verify', (e: any) => {
      //The event informs that the payment starts processing through 3D flow.
      payload.processingHandler(e?.data)
    })
    form.on('fail', (e: any) => {
      // The event informs that the payment has been declined. Contains an object with an error code and error message.
      //Merchant's code
      payload.failHandler(e?.data)
    })
    form.on('error', (e: any) => {
      // Event of any form error after a click on submit button (validation error). Contains an object with a message.
      payload.errorHandler(e)
    })

    // Save the card brand when user entered card number to send it in success event
    form.on('card', (e: any) => {
      this.brandCard = e.data?.card?.brand || ''
      payload.enterCardInfoHandler?.(e)
    })

    this.isHandlerActive = true
  }
}
