import { saveHyrosLayerOrder } from 'shared/api/hyros/endpoints'
import { logger } from 'shared/utils/logger'

import { CheckoutPurchase } from 'features/payment/checkout/shared/types'

import { AbstractSystem } from './AbstractSystem'

import { SYSTEMS_NAMES } from '../Constants'

export class HyrosDataLayer extends AbstractSystem {
  constructor() {
    super(SYSTEMS_NAMES.hyrosDataLayer, true)
  }

  init() {}

  track() {}

  logger(eventName: string, variables: Record<string, any> = {}) {
    logger.info(`%cHYROS EVENT: ${eventName}`, 'color: #4285F4; font-weight: bold;', variables)
  }

  purchase(eventName: string, data: CheckoutPurchase) {
    const {
      currency,
      order_id,
      discount,
      userEmail,
      content_id,
      tariff,
      ipAddress,
      subscription_price,
    } = data

    const hyrosData = {
      currency: currency,
      price: subscription_price,
      orderId: order_id,
      orderDiscount: discount,
      email: userEmail,
      productName: content_id,
      productId: tariff,
      ipAddress,
    }

    saveHyrosLayerOrder(hyrosData)

    this.logger(eventName, data)
  }
}
