/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query'

import { useHttpClient } from '../../useHttpClient'

import type { ErrorType } from '../../useHttpClient'
import type {
  Usersusercreatev2modelresponse,
  Usersusercreatev2model400response,
  Usersusercreatev2modelrequest,
  Paymentsformmerchantdatamodelresponse,
  Paymentsformmerchantdatamodel400response,
  Paymentsformmerchantdatamodelrequest,
  Paymentsinitpaypaltransactionmodelresponse,
  Paymentsinitpaypaltransactionmodel400response,
  Paymentsinitpaypaltransactionmodelrequest,
  Usersfinalizefunnelregistrationmodelresponse,
  Usersfinalizefunnelregistrationmodel400response,
  Usersfinalizefunnelregistrationmodelrequest,
  Userslocationmodelresponse,
  Userslocationmodel400response,
  Usersemailconsentmodelresponse,
  Usersemailconsentmodelrequest,
  Usersfunnelprofilemodelresponse,
  Usersfunnelprofilemodel400response,
  Usersfunnelprofilemodel404response,
  Usersresetpasswordmodelresponse,
  Usersresetpasswordmodel400response,
  Usersresetpasswordmodelrequest,
} from '../model'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'

export const usePostV2UsersUserCreateHook = () => {
  const postV2UsersUserCreate = useHttpClient<Usersusercreatev2modelresponse>()

  return (usersusercreatev2modelrequest: Usersusercreatev2modelrequest) => {
    return postV2UsersUserCreate({
      url: `/v2/users/user_create`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: usersusercreatev2modelrequest,
    })
  }
}

export const usePostV2UsersUserCreateMutationOptions = <
  TError = ErrorType<Usersusercreatev2model400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV2UsersUserCreateHook>>>,
    TError,
    { data: Usersusercreatev2modelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostV2UsersUserCreateHook>>>,
  TError,
  { data: Usersusercreatev2modelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const postV2UsersUserCreate = usePostV2UsersUserCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostV2UsersUserCreateHook>>>,
    { data: Usersusercreatev2modelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return postV2UsersUserCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostV2UsersUserCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostV2UsersUserCreateHook>>>
>
export type PostV2UsersUserCreateMutationBody = Usersusercreatev2modelrequest
export type PostV2UsersUserCreateMutationError = ErrorType<Usersusercreatev2model400response>

export const usePostV2UsersUserCreate = <
  TError = ErrorType<Usersusercreatev2model400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV2UsersUserCreateHook>>>,
    TError,
    { data: Usersusercreatev2modelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePostV2UsersUserCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const usePostPaymentFormMerchantDataHook = () => {
  const postPaymentFormMerchantData = useHttpClient<Paymentsformmerchantdatamodelresponse>()

  return (paymentsformmerchantdatamodelrequest: Paymentsformmerchantdatamodelrequest) => {
    return postPaymentFormMerchantData({
      url: `/payment/form_merchant_data`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: paymentsformmerchantdatamodelrequest,
    })
  }
}

export const usePostPaymentFormMerchantDataMutationOptions = <
  TError = ErrorType<Paymentsformmerchantdatamodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentFormMerchantDataHook>>>,
    TError,
    { data: Paymentsformmerchantdatamodelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostPaymentFormMerchantDataHook>>>,
  TError,
  { data: Paymentsformmerchantdatamodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const postPaymentFormMerchantData = usePostPaymentFormMerchantDataHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentFormMerchantDataHook>>>,
    { data: Paymentsformmerchantdatamodelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return postPaymentFormMerchantData(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostPaymentFormMerchantDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostPaymentFormMerchantDataHook>>>
>
export type PostPaymentFormMerchantDataMutationBody = Paymentsformmerchantdatamodelrequest
export type PostPaymentFormMerchantDataMutationError =
  ErrorType<Paymentsformmerchantdatamodel400response>

export const usePostPaymentFormMerchantData = <
  TError = ErrorType<Paymentsformmerchantdatamodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentFormMerchantDataHook>>>,
    TError,
    { data: Paymentsformmerchantdatamodelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePostPaymentFormMerchantDataMutationOptions(options)

  return useMutation(mutationOptions)
}

export const usePostPaymentPaypalInitTransactionHook = () => {
  const postPaymentPaypalInitTransaction =
    useHttpClient<Paymentsinitpaypaltransactionmodelresponse>()

  return (paymentsinitpaypaltransactionmodelrequest: Paymentsinitpaypaltransactionmodelrequest) => {
    return postPaymentPaypalInitTransaction({
      url: `/payment/paypal/init_transaction`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: paymentsinitpaypaltransactionmodelrequest,
    })
  }
}

export const usePostPaymentPaypalInitTransactionMutationOptions = <
  TError = ErrorType<Paymentsinitpaypaltransactionmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentPaypalInitTransactionHook>>>,
    TError,
    { data: Paymentsinitpaypaltransactionmodelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostPaymentPaypalInitTransactionHook>>>,
  TError,
  { data: Paymentsinitpaypaltransactionmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const postPaymentPaypalInitTransaction = usePostPaymentPaypalInitTransactionHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentPaypalInitTransactionHook>>>,
    { data: Paymentsinitpaypaltransactionmodelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return postPaymentPaypalInitTransaction(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostPaymentPaypalInitTransactionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostPaymentPaypalInitTransactionHook>>>
>
export type PostPaymentPaypalInitTransactionMutationBody = Paymentsinitpaypaltransactionmodelrequest
export type PostPaymentPaypalInitTransactionMutationError =
  ErrorType<Paymentsinitpaypaltransactionmodel400response>

export const usePostPaymentPaypalInitTransaction = <
  TError = ErrorType<Paymentsinitpaypaltransactionmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostPaymentPaypalInitTransactionHook>>>,
    TError,
    { data: Paymentsinitpaypaltransactionmodelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePostPaymentPaypalInitTransactionMutationOptions(options)

  return useMutation(mutationOptions)
}

export const usePatchUsersHook = () => {
  const patchUsers = useHttpClient<Usersfinalizefunnelregistrationmodelresponse>()

  return (
    usersfinalizefunnelregistrationmodelrequest: Usersfinalizefunnelregistrationmodelrequest
  ) => {
    return patchUsers({
      url: `/users`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: usersfinalizefunnelregistrationmodelrequest,
    })
  }
}

export const usePatchUsersMutationOptions = <
  TError = ErrorType<Usersfinalizefunnelregistrationmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchUsersHook>>>,
    TError,
    { data: Usersfinalizefunnelregistrationmodelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchUsersHook>>>,
  TError,
  { data: Usersfinalizefunnelregistrationmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const patchUsers = usePatchUsersHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchUsersHook>>>,
    { data: Usersfinalizefunnelregistrationmodelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return patchUsers(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUsersMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchUsersHook>>>
>
export type PatchUsersMutationBody = Usersfinalizefunnelregistrationmodelrequest
export type PatchUsersMutationError = ErrorType<Usersfinalizefunnelregistrationmodel400response>

export const usePatchUsers = <
  TError = ErrorType<Usersfinalizefunnelregistrationmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchUsersHook>>>,
    TError,
    { data: Usersfinalizefunnelregistrationmodelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePatchUsersMutationOptions(options)

  return useMutation(mutationOptions)
}

export const useGetLocationHook = () => {
  const getLocation = useHttpClient<Userslocationmodelresponse>()

  return (signal?: AbortSignal) => {
    return getLocation({ url: `/location`, method: 'get', signal })
  }
}

export const getGetLocationQueryKey = () => {
  return [`/location`] as const
}

export const useGetLocationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
  TError = ErrorType<Userslocationmodel400response>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLocationQueryKey()

  const getLocation = useGetLocationHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>> = ({
    signal,
  }) => getLocation(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetLocationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>
>
export type GetLocationQueryError = ErrorType<Userslocationmodel400response>

export const useGetLocation = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
  TError = ErrorType<Userslocationmodel400response>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLocationQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const usePutUsersEmailConsentHook = () => {
  const putUsersEmailConsent = useHttpClient<Usersemailconsentmodelresponse>()

  return (usersemailconsentmodelrequest: Usersemailconsentmodelrequest) => {
    return putUsersEmailConsent({
      url: `/users/email_consent`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: usersemailconsentmodelrequest,
    })
  }
}

export const usePutUsersEmailConsentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutUsersEmailConsentHook>>>,
    TError,
    { data: Usersemailconsentmodelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutUsersEmailConsentHook>>>,
  TError,
  { data: Usersemailconsentmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const putUsersEmailConsent = usePutUsersEmailConsentHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutUsersEmailConsentHook>>>,
    { data: Usersemailconsentmodelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return putUsersEmailConsent(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutUsersEmailConsentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutUsersEmailConsentHook>>>
>
export type PutUsersEmailConsentMutationBody = Usersemailconsentmodelrequest
export type PutUsersEmailConsentMutationError = ErrorType<unknown>

export const usePutUsersEmailConsent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutUsersEmailConsentHook>>>,
    TError,
    { data: Usersemailconsentmodelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePutUsersEmailConsentMutationOptions(options)

  return useMutation(mutationOptions)
}

export const useGetUsersFunnelProfileHook = () => {
  const getUsersFunnelProfile = useHttpClient<Usersfunnelprofilemodelresponse>()

  return (signal?: AbortSignal) => {
    return getUsersFunnelProfile({ url: `/users/funnel_profile`, method: 'get', signal })
  }
}

export const getGetUsersFunnelProfileQueryKey = () => {
  return [`/users/funnel_profile`] as const
}

export const useGetUsersFunnelProfileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
  TError = ErrorType<Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersFunnelProfileQueryKey()

  const getUsersFunnelProfile = useGetUsersFunnelProfileHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>
  > = ({ signal }) => getUsersFunnelProfile(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUsersFunnelProfileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>
>
export type GetUsersFunnelProfileQueryError = ErrorType<
  Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response
>

export const useGetUsersFunnelProfile = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
  TError = ErrorType<Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUsersFunnelProfileQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const usePostResetPasswordHook = () => {
  const postResetPassword = useHttpClient<Usersresetpasswordmodelresponse>()

  return (usersresetpasswordmodelrequest: Usersresetpasswordmodelrequest) => {
    return postResetPassword({
      url: `/reset_password`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: usersresetpasswordmodelrequest,
    })
  }
}

export const usePostResetPasswordMutationOptions = <
  TError = ErrorType<Usersresetpasswordmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    TError,
    { data: Usersresetpasswordmodelrequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
  TError,
  { data: Usersresetpasswordmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const postResetPassword = usePostResetPasswordHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    { data: Usersresetpasswordmodelrequest }
  > = (props) => {
    const { data } = props ?? {}

    return postResetPassword(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>
>
export type PostResetPasswordMutationBody = Usersresetpasswordmodelrequest
export type PostResetPasswordMutationError = ErrorType<Usersresetpasswordmodel400response>

export const usePostResetPassword = <
  TError = ErrorType<Usersresetpasswordmodel400response>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    TError,
    { data: Usersresetpasswordmodelrequest },
    TContext
  >
}) => {
  const mutationOptions = usePostResetPasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
