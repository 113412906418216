import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { FirehoseClient, PutRecordBatchCommand } from '@aws-sdk/client-firehose'
import {
  fromCognitoIdentityPool,
  CognitoIdentityCredentials,
} from '@aws-sdk/credential-provider-cognito-identity'
import {
  defaultRetryDecider,
  defaultDelayDecider,
  StandardRetryStrategy,
  StandardRetryStrategyOptions,
} from '@aws-sdk/middleware-retry'
import { SdkError } from '@aws-sdk/types'
import { fromBase64 } from '@aws-sdk/util-base64'
import { normalizeProvider } from '@aws-sdk/util-middleware'

import { ENV, SYSTEMS_NAMES } from 'shared/providers/AnalyticsProvider/analytics/Constants'
import { Config } from 'shared/providers/AnalyticsProvider/analytics/Interfaces/Config'
import { UtilsNew } from 'shared/providers/AnalyticsProvider/analytics/helpers/UtilsNew'
import { compose } from 'shared/utils/fp-helpers'
import { logger } from 'shared/utils/logger'

import { AbstractSystem } from './AbstractSystem'

// unfortunately arguments are untyped here
// TODO: review transformation because it uses deprecated functions unescape and btoa
const transform = compose<Uint8Array>(
  fromBase64,
  btoa,
  unescape,
  encodeURIComponent,
  JSON.stringify
)

const LOGGABLE_FIELDS = ['event_type', 'event_label']

const getLogString = (data: any) =>
  LOGGABLE_FIELDS.map((key) => data[key])
    .filter(Boolean)
    .join(' | ')

interface InitParams {
  config: Config
  id: string
  maxRetryAttempts?: number
}

type RecordData = Awaited<ReturnType<AwsAnalytic['getRecordData']>>

type DelayDecider = Required<StandardRetryStrategyOptions>['delayDecider']
type RetryDecider = Required<StandardRetryStrategyOptions>['retryDecider']

/**
 * Decides if a retry is needed or not. It extends default implementation with handler for network issues
 * @link https://github.com/aws/aws-sdk-js-v3/blob/main/packages/middleware-retry/src/retryDecider.ts
 */
const retryDecider: RetryDecider = (error: SdkError) => {
  // if metadata is empty it means that request is failed on client side
  if (!error.$metadata) {
    return true
  }

  return defaultRetryDecider(error)
}

/**
 * Increased base delay for a retry with exponential backoff time
 */
const RETRY_DELAY_BASE = 500

const SEND_INTERVAL = 1500

/**
 * Calculates a capped, fully-jittered exponential backoff time
 * @link https://github.com/aws/aws-sdk-js-v3/blob/main/packages/middleware-retry/src/delayDecider.ts
 */
const delayDecider: DelayDecider = (delayBase, attempts) =>
  defaultDelayDecider(Math.max(delayBase, RETRY_DELAY_BASE), attempts)

export class AwsAnalytic extends AbstractSystem {
  private streamName: string = ''
  private envMode: string = ''
  private firehose: FirehoseClient | undefined
  private releaseDate: string = ''
  private maxRetryAttempts: number = 5
  private eventsQueue: RecordData[] = []
  private sendingInterval: NodeJS.Timeout | null = null

  constructor() {
    super(SYSTEMS_NAMES.amazon, false)
  }

  public init({ config, id, maxRetryAttempts }: InitParams) {
    if (this.isInit) {
      return
    }

    this.streamName = config.streamName || 'aws_stream_name'
    this.envMode = config.envMode || ENV.develop
    this.releaseDate = config.releaseDate || 'default_date'
    this.maxRetryAttempts = maxRetryAttempts ?? this.maxRetryAttempts

    this.firehose = new FirehoseClient({
      region: 'us-east-1',
      credentials: fromCognitoIdentityPool({
        identityPoolId: id,
        client: new CognitoIdentityClient({
          region: 'us-east-1',
          retryStrategy: new StandardRetryStrategy(normalizeProvider(this.maxRetryAttempts), {
            retryDecider,
            delayDecider,
          }),
        }),
      }),
      retryStrategy: new StandardRetryStrategy(normalizeProvider(this.maxRetryAttempts), {
        retryDecider,
        delayDecider,
      }),
    })

    this.sendingInterval = setInterval(() => {
      if (this.eventsQueue.length > 0) {
        this.sendEvents()
      }
    }, SEND_INTERVAL)

    this.isInit = true
  }

  public async track(eventName: string = '', eventData: object = {}): Promise<void> {
    if (!this.firehose) {
      return
    }

    let recordData = null

    try {
      recordData = await this.getRecordData(eventName, eventData)
    } catch (err) {
      logger.error(Error('Analytics.AWS collect record data error: ', { cause: err }))

      return
    }

    logger.info(`%cAMAZON EVENT: ${getLogString(recordData)}`, 'color: green; font-weight: bold;', {
      recordData,
    })

    this.eventsQueue.push(recordData)
  }

  private async sendEvents() {
    if (!this.firehose) {
      return
    }

    const eventsQueue = [...this.eventsQueue]
    this.eventsQueue = []

    const command = new PutRecordBatchCommand({
      DeliveryStreamName: this.streamName,
      Records: eventsQueue.map((data) => ({ Data: transform(data) })),
    })

    try {
      await this.firehose.send(command)
      for (let item of eventsQueue) {
        logger.info(`Event ${item.event_type} successfully sent.`)
      }
    } catch (err) {
      logger.debug('Analytics.AWS error:', err)
      logger.error(Error('Analytics.AWS sending error', { cause: err }), {
        tags: {
          'amazon.event_string': eventsQueue.map((data) => getLogString(data)),
          'amazon.event_type': eventsQueue.map(({ event_type }) => event_type),
        },
      })
    }
  }

  public purchase(event_name: string, data: object): void {
    this.track(event_name || 'purchase', data)
  }

  public async getAwsId(): Promise<string> {
    const credentials = (await this.firehose?.config.credentials()) as CognitoIdentityCredentials

    return credentials.identityId
  }

  private async getRecordData(eventName: string, eventData: object) {
    const defaultRecordData = UtilsNew.getDefaultRecordData(eventName)
    const awsId = await this.getAwsId()

    return {
      ...defaultRecordData,
      ...eventData,
      mode: this.envMode,
      release_date: this.releaseDate,
      aws_id: awsId,
    }
  }

  public reset() {
    this.sendingInterval && clearInterval(this.sendingInterval)
  }
}
