import Cookies from 'js-cookie'

import { ONBOARDING_FINISHED_COOKIE_NAME } from 'shared/providers/FlowManagerProvider/constants'

export const getOnboardingExpirationDate = () => {
  const cookieValue = Cookies.get(ONBOARDING_FINISHED_COOKIE_NAME)

  if (cookieValue) {
    return new Date(cookieValue)
  }

  return undefined
}
