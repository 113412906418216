import { PaymentSystemName } from 'shared/providers/PaymentProvider/solid-payment/types/payment'

export const MAX_COUNT_ERROR_HANDLER = 20

export enum PAYMENT_METHOD {
  BANK_CARD = 'card',
  APPLE_PAY = 'apple_pay',
  PAYPAL = 'paypal',
}

export const PAYMENT_METHOD_NAME = {
  [PAYMENT_METHOD.BANK_CARD]: 'bank_card',
  [PAYMENT_METHOD.APPLE_PAY]: 'apple_pay',
  [PAYMENT_METHOD.PAYPAL]: 'paypal',
}

export const PAYMENT_METHOD_TO_PAYMENT_SYSTEM_NAME_MAP: Record<PAYMENT_METHOD, PaymentSystemName> =
  {
    [PAYMENT_METHOD.BANK_CARD]: 'bankCard',
    [PAYMENT_METHOD.APPLE_PAY]: 'bankCard',
    [PAYMENT_METHOD.PAYPAL]: 'payPal',
  }

export const SOLID_PAYMENT_FORM_ENTITY_TO_PAYMENT_METHOD_MAP = {
  applebtn: PAYMENT_METHOD.APPLE_PAY,
  // googlebtn: PAYMENT_METHOD.GOOGLE_PAY,
  form: PAYMENT_METHOD.BANK_CARD,
}

export enum PERIOD_UNIT {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const DAYS_TO_SUBSCRIPTION_PERIOD_MAP: {
  [key: number]: { count: number; unit: PERIOD_UNIT }
} = {
  7: {
    count: 1,
    unit: PERIOD_UNIT.WEEK,
  },
  30: {
    count: 1,
    unit: PERIOD_UNIT.MONTH,
  },
  31: {
    count: 1,
    unit: PERIOD_UNIT.MONTH,
  },
  60: {
    count: 2,
    unit: PERIOD_UNIT.MONTH,
  },
  90: {
    count: 3,
    unit: PERIOD_UNIT.MONTH,
  },
  120: {
    count: 4,
    unit: PERIOD_UNIT.MONTH,
  },
  150: {
    count: 5,
    unit: PERIOD_UNIT.MONTH,
  },
  180: {
    count: 6,
    unit: PERIOD_UNIT.MONTH,
  },
  210: {
    count: 7,
    unit: PERIOD_UNIT.MONTH,
  },
  240: {
    count: 8,
    unit: PERIOD_UNIT.MONTH,
  },
  270: {
    count: 9,
    unit: PERIOD_UNIT.MONTH,
  },
  300: {
    count: 10,
    unit: PERIOD_UNIT.MONTH,
  },
  330: {
    count: 11,
    unit: PERIOD_UNIT.MONTH,
  },
  365: {
    count: 1,
    unit: PERIOD_UNIT.YEAR,
  },
}

export const DEFAULT_DISCOUNT_DURATION_IN_MINUTES = 10

export enum CURRENCY_CODE {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum CURRENCY_SYMBOL {
  EUR = '€',
  USD = '$',
  GBP = '£',
}

export const DEFAULT_CURRENCY = CURRENCY_CODE.USD

export const COUNTRY_TO_CURRENCY_CODE_MAP = {
  AT: CURRENCY_CODE.EUR,
  BE: CURRENCY_CODE.EUR,
  BG: CURRENCY_CODE.EUR,
  HR: CURRENCY_CODE.EUR,
  CY: CURRENCY_CODE.EUR,
  CZ: CURRENCY_CODE.EUR,
  DK: CURRENCY_CODE.EUR,
  EE: CURRENCY_CODE.EUR,
  FI: CURRENCY_CODE.EUR,
  FR: CURRENCY_CODE.EUR,
  DE: CURRENCY_CODE.EUR,
  GR: CURRENCY_CODE.EUR,
  HU: CURRENCY_CODE.EUR,
  IE: CURRENCY_CODE.EUR,
  IT: CURRENCY_CODE.EUR,
  LV: CURRENCY_CODE.EUR,
  LT: CURRENCY_CODE.EUR,
  LU: CURRENCY_CODE.EUR,
  MT: CURRENCY_CODE.EUR,
  NL: CURRENCY_CODE.EUR,
  PL: CURRENCY_CODE.EUR,
  PT: CURRENCY_CODE.EUR,
  RO: CURRENCY_CODE.EUR,
  SK: CURRENCY_CODE.EUR,
  SI: CURRENCY_CODE.EUR,
  ES: CURRENCY_CODE.EUR,
  SE: CURRENCY_CODE.EUR,
  NO: CURRENCY_CODE.EUR,
  IS: CURRENCY_CODE.EUR,
  LI: CURRENCY_CODE.EUR,
  GB: CURRENCY_CODE.GBP,
  IM: CURRENCY_CODE.GBP,
  JE: CURRENCY_CODE.GBP,
  GG: CURRENCY_CODE.GBP,
}
