import { AbstractPayment } from './abstractPayment'
import { BankCard } from './paymentSystems/bankCard'
import { PayPal } from './paymentSystems/payPal'

import { PaymentSystemName } from '../types/payment'

export class StrategyManager {
  private state: any
  constructor() {
    this.state = {
      systems: [new BankCard(), new PayPal()],
    }
  }

  async getStrategy(systemName: PaymentSystemName): Promise<AbstractPayment> {
    for await (const system of this.state.systems) {
      if (await system.isSupport(systemName)) {
        return system
      }
    }

    throw new Error(`${systemName} - 'no such class exists`)
  }
}
