import { PAY_PAL } from 'shared/providers/PaymentProvider/solid-payment/constants'
import { AbstractPayment } from 'shared/providers/PaymentProvider/solid-payment/services/abstractPayment'
import { logger } from 'shared/utils/logger'

export class PayPal extends AbstractPayment {
  private buttonWrapperDiv: HTMLElement | null | undefined
  private isHandlerActive: boolean
  private unsubscribeHandlers: () => void

  constructor() {
    super(PAY_PAL)
    this.isHandlerActive = false
    this.buttonWrapperDiv = null
    this.unsubscribeHandlers = () => {}
  }

  async initPayment(payload: any): Promise<any> {
    const responseInit: any = await super.initPayment(payload)

    const url = responseInit.script_url

    if (!url) {
      logger.debug('PayPal script url is missed: ', responseInit)
      throw Error('Script url is required for init PayPal button')
    }

    this.insertScript(url)
    this.activeHandlers(payload)

    return responseInit
  }

  protected async normalizeInitData(payload: any) {
    return {
      payment_token: payload.paymentToken,
      selected_product: payload.id,
    }
  }

  public clearAllHandlers() {
    this.unsubscribeHandlers()
  }

  activeHandlers(payload: any) {
    this.clearAllHandlers()

    const unsubscribeClick = this.onClick(payload.onClickHandler)
    const unsubscribeError = this.buttonErrorHandler(payload.errorHandler)
    const unsubscribeReady = this.buttonReadyHandler(payload.readyHandler)
    const unsubscribeProcessed = this.orderProcessedHandler(payload.successHandler)

    this.unsubscribeHandlers = () => {
      unsubscribeClick()
      unsubscribeError()
      unsubscribeReady()
      unsubscribeProcessed()
    }

    this.isHandlerActive = true
    this.buttonWrapperDiv?.setAttribute('listener', 'true')
  }

  insertScript(url: string) {
    const buttonWrapper = document.getElementById('paypal-button')

    if (!buttonWrapper) {
      throw Error('buttonWrapper is required for init PayPal button')
    }

    const { head } = document

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = url

    script.onerror = (err) => {
      const tags = {
        'app.feature': 'payments',
        'payment.status': 'initialization',
        'payment.state': 'script-loading-error',
        'payment.method_type': 'paypal',
        'payment.processor': 'solid',
      }

      logger.error(Error(`Payments.paypal.loadScript.error`, { cause: err }), {
        tags,
      })
    }

    script.setAttribute('data-script', ' ')
    script.setAttribute('data-btn-id', 'solid_pay_btn')
    script.setAttribute('data-overlay', 'true')
    script.setAttribute('data-tittle', 'Tittle for payment widget')
    script.setAttribute('data-description', 'widget.data-description')
    script.setAttribute('data-shape', 'rect')

    head.insertBefore(script, head.firstChild)

    this.buttonWrapperDiv = buttonWrapper
  }

  buttonErrorHandler(callback: any) {
    const btn = this.buttonWrapperDiv

    function onError(event: any) {
      callback(event)
    }

    btn?.addEventListener('button-error', onError, false)

    return () => {
      btn?.removeEventListener('button-error', onError)
    }
  }

  buttonReadyHandler(callback = (_event: any) => {}) {
    const btn = this.buttonWrapperDiv

    function onReady(event: any) {
      logger.debug('button-ready', event)
      callback(event)
    }

    btn?.addEventListener('button-ready', onReady, false)

    return () => {
      btn?.removeEventListener('button-ready', onReady)
    }
  }

  orderProcessedHandler(callback: any) {
    const btn = this.buttonWrapperDiv

    function onProcessed(event: any) {
      logger.debug('order-processed', event)
      callback(event.detail.data) //return order, pay_info
    }

    btn?.addEventListener('order-processed', onProcessed, false)

    return () => {
      btn?.removeEventListener('order-processed', onProcessed)
    }
  }

  onClick(callback = (_event: any) => {}) {
    const btn = this.buttonWrapperDiv

    function onProcessing(event: any) {
      logger.debug('order-started-processing', event)
      callback(event)
    }

    btn?.addEventListener('order-started-processing', onProcessing, false)

    return () => {
      btn?.removeEventListener('order-started-processing', onProcessing)
    }
  }
}
