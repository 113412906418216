import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Usersfunnelprofilemodelresponse,
  usePostV2UsersUserCreate,
  useGetUsersFunnelProfile,
  getGetUsersFunnelProfileQueryKey,
} from 'shared/api/forerunner'
import { useAuthToken } from 'shared/providers/AuthTokenProvider'
import { logger } from 'shared/utils/logger'
import { StorageValue } from 'shared/utils/storage'

import { AuthStatus } from './constants'

export interface UserModel extends Usersfunnelprofilemodelresponse {}

interface AuthUserContextInterface {
  models: {
    user: UserModel | null
    authenticated: boolean
    authStatus: AuthStatus
    loginDeeplink: string | null
  }
  operations: {
    signup: ReturnType<typeof usePostV2UsersUserCreate>['mutateAsync']
    signout: () => void
    updateUser: (user: Partial<UserModel>) => void
    saveLoginDeeplink: (link: string) => void
  }
}

const AuthUserContext = React.createContext<AuthUserContextInterface | null>(null)

function useAuthUser() {
  const context = React.useContext(AuthUserContext)

  if (!context) {
    throw new Error(`useAuthUser must be used within Auth`)
  }

  return context
}

interface AuthProps {
  children?: React.ReactNode
}

const AuthUserProvider = ({ children }: AuthProps): JSX.Element => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.INITIAL)
  const [loginDeeplink, setLoginDeeplink] = useState<string | null>(() => deepLinkStorage.get())

  const { token, isReady, setToken } = useAuthToken()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isReady && authStatus === AuthStatus.INITIAL) {
      setAuthStatus(token ? AuthStatus.PENDING : AuthStatus.DONE)
    }
  }, [authStatus, token, isReady])

  const queryKey = useMemo(() => [...getGetUsersFunnelProfileQueryKey(), token], [token])

  const { data: user = null } = useGetUsersFunnelProfile({
    query: {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 2,
      enabled: Boolean(token),
      queryKey,
      onSuccess: () => {
        setAuthStatus(AuthStatus.DONE)
      },
      onError: (error) => {
        if (typeof error.response?.status === 'number' && error.response.status < 500) {
          const tags = {
            'app.component': 'AuthUserProvider',
          }

          logger.error(Error('Auth error'), { cause: error }, { tags })
          signout()
        }

        setAuthStatus(AuthStatus.DONE)
      },
    },
  })

  const updateUser = useCallback(
    (updatedFields: Partial<UserModel>) => {
      const existingUser = queryClient.getQueryData(queryKey)

      const updatedUser = {
        ...(existingUser as NonNullable<UserModel>),
        ...updatedFields,
      }

      queryClient.setQueryData(queryKey, updatedUser)
    },
    [queryClient, queryKey]
  )

  const saveLoginDeeplink = useCallback((deeplink: string) => {
    setLoginDeeplink(deeplink)
    deepLinkStorage.set(deeplink)
  }, [])

  const { mutateAsync: makeSignupRequest } = usePostV2UsersUserCreate()

  const signup = useCallback<AuthUserContextInterface['operations']['signup']>(
    (...params) => {
      const request = makeSignupRequest(...params)

      request
        .then((user) => {
          setToken(user.finalize_registration_token || '')

          return user
        })
        .catch(() => {})

      return request
    },
    [makeSignupRequest, setToken]
  )

  const signout = useCallback(() => {
    queryClient.clear()
    setToken('')
  }, [queryClient, setToken])

  const api = useMemo<AuthUserContextInterface>(
    () => ({
      models: {
        user,
        authenticated: Boolean(token),
        authStatus,
        loginDeeplink,
      },
      operations: {
        updateUser,
        signup,
        signout,
        saveLoginDeeplink,
      },
    }),
    [user, token, authStatus, loginDeeplink, updateUser, signup, signout, saveLoginDeeplink]
  )

  return <AuthUserContext.Provider value={api}>{children}</AuthUserContext.Provider>
}

const deepLinkStorage = new StorageValue({
  key: 'deepLink',
})

export { AuthUserProvider, useAuthUser }
