import { logger } from 'shared/utils/logger'

import { AbstractSystem } from './AbstractSystem'

import { SYSTEMS_NAMES } from '../Constants'

type ExtendedWindow = Window &
  typeof globalThis & {
    dataLayer: Array<Record<string, any>>
  }

export class GTMDataLayer extends AbstractSystem {
  constructor() {
    super(SYSTEMS_NAMES.gtmDataLayer, true)
  }

  init() {}

  getDataLayer() {
    const extendedWindow = window as ExtendedWindow

    if (!extendedWindow.dataLayer) {
      extendedWindow.dataLayer = []
    }

    return extendedWindow.dataLayer
  }

  track(eventName: string, variables: Record<string, any> = {}) {
    const dataLayer = this.getDataLayer()
    const { isOnboardingScreen, screenName, actionType } = this.parseEventName(eventName)

    if (isOnboardingScreen && actionType === 'click') {
      dataLayer.push({
        event: 'quiz_question_answered',
        Question_name: screenName,
        Question_answer: variables.event_label,
      })
    } else {
      dataLayer.push({
        event: eventName,
        ...variables,
      })
    }

    const { event_label: eventLabel, ...data } = variables

    logger.info(
      `%cGTM EVENT: ${[screenName, actionType, eventLabel].filter(Boolean).join(' | ')}`,
      'color: #4285F4; font-weight: bold;',
      data
    )
  }

  purchase(eventName: string, data: object) {
    this.track(eventName, data)
  }

  parseEventName(eventName: string) {
    // eventName has two parts with "__", so replacing the first one to split string into 2 parts (not 3+)
    const [screenName, actionType] = eventName.replace('__', '*').split('*')
    const isOnboardingScreen = screenName.startsWith('ob_')

    return {
      screenName,
      actionType,
      isOnboardingScreen,
    }
  }
}
