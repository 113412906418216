import Router from 'next/router'
import React from 'react'

import { initLogger, logger } from 'shared/utils/logger'

function useLogger() {
  React.useEffect(() => {
    initLogger()
  }, [])

  React.useEffect(() => {
    const handleRouteChangeError = (err: unknown, url: string) => {
      const { cancelled } = err as { cancelled: boolean }

      if (!cancelled) {
        logger.info(`Router.routeChangeError from ${Router.asPath} to ${url}`)
        logger.error(Error('Router.routeChangeError', { cause: err }))
      }
    }

    Router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      Router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [])
}

export { useLogger }
