import { getColorValue } from 'shared/utils/color'

export const getStyles = () => {
  return {
    form_body: {
      'font-family':
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    card_number: {
      '.input-block': {
        position: 'static',
        '.label': {
          display: 'none',
        },
        input: {
          height: '56px',
          'background-color': getColorValue('surface-default'),
          color: getColorValue('text-primary'),
          'font-size': '16px',
          'line-height': '24px',
          'border-radius': '12px 12px 0 0',
          'border-color': getColorValue('border-primary-subdued'),
          '::placeholder': {
            color: getColorValue('text-subdued'),
          },
          ':focus': {
            'border-color': getColorValue('border-primary-default'),
          },
        },
        '.brand-icon': {
          top: '1.5em',
        },
      },
    },
    expiry_date: {
      '.input-block': {
        input: {
          height: '56px',
          'background-color': getColorValue('surface-default'),
          color: getColorValue('text-primary'),
          'font-size': '16px',
          'line-height': '24px',
          padding: '16px 12px !important',
          'border-radius': '0 0 0 12px',
          'border-color': getColorValue('border-primary-subdued'),
          '::placeholder': {
            color: getColorValue('text-subdued'),
          },
          ':focus': {
            'border-color': getColorValue('border-primary-default'),
          },
        },
        '.label': {
          display: 'none',
        },
      },
    },
    card_cvv: {
      '.input-block': {
        input: {
          height: '56px',
          'background-color': getColorValue('surface-default'),
          color: getColorValue('text-primary'),
          'font-size': '16px',
          'line-height': '24px',
          padding: '16px 12px !important',
          'border-radius': '0 0 12px 0',
          'border-color': getColorValue('border-primary-subdued'),
          '::placeholder': {
            color: getColorValue('text-subdued'),
          },
          ':focus': {
            'border-color': getColorValue('border-primary-default'),
          },
        },
        '.label': {
          display: 'none',
        },
        '.tooltip-icon': {
          display: 'none',
        },
      },
    },
    zip_code: {
      '.input-block': {
        input: {
          height: '56px',
          'background-color': getColorValue('surface-default'),
          color: getColorValue('text-primary'),
          'font-size': '16px',
          'line-height': '24px',
          padding: '16px 12px !important',
          'border-radius': '12px',
          'border-color': getColorValue('border-primary-subdued'),
          '::placeholder': {
            color: getColorValue('text-subdued'),
          },
          ':focus': {
            'border-color': getColorValue('border-primary-default'),
          },
        },
        '.label': {
          color: getColorValue('text-primary'),
          'font-size': '16px',
          'line-height': '24px',
          'font-weight': '700',
        },
        '.tooltip-icon': {
          display: 'none',
        },
        'margin-bottom': '16px',
      },
    },
  }
}
