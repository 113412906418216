import { REQUEST_HEADERS } from './constants'
import { setOptions } from './helpers/utils'
import { InitSolidPayment, ValidatePayment } from './interfaces/payment'
import { StrategyManager } from './services/strategyManager'
import { Init } from './types/payment'
import { PaymentSystemName } from './types/payment'

class SolidPayment {
  public strategyManager: StrategyManager

  constructor() {
    this.strategyManager = new StrategyManager()
  }

  public async setHeaders(props: InitSolidPayment) {
    return setOptions(props, REQUEST_HEADERS)
  }

  public async init(payment: PaymentSystemName, payload: Init) {
    const strategy = await this.strategyManager.getStrategy(payment)

    return strategy.initPayment(payload)
  }

  public async validate(payment: PaymentSystemName, payload: ValidatePayment) {
    const strategy = await this.strategyManager.getStrategy(payment)

    return strategy.validatePayment(payload)
  }

  public async clearAllHandlers(payment: PaymentSystemName) {
    const strategy = await this.strategyManager.getStrategy(payment)

    // @ts-expect-error
    return strategy.clearAllHandlers()
  }
}

export const Solid = new SolidPayment()
