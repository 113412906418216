import React from 'react'

import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { usePayment } from 'shared/providers/PaymentProvider'
import { logger } from 'shared/utils/logger'

interface SolidPaymentPreloaderProps {
  /**
   * The child nodes component has wrapped
   */
  children?: React.ReactNode
}

export const SolidPaymentPreloader = ({ children }: SolidPaymentPreloaderProps) => {
  const {
    models: { user },
  } = useAuthUser()
  const { solidPaymentAdapter } = usePayment()

  // preload Solid Payment Form SDK
  React.useEffect(() => {
    if (user) {
      solidPaymentAdapter.solid.strategyManager.getStrategy('bankCard').then((strategy) => {
        // @ts-expect-error
        strategy.loadPaymentForm().catch((error) => {
          logger.warn(Error('Load payment form script error', { cause: error }))
        })
      })
    }
  }, [solidPaymentAdapter, user])

  return <>{children}</>
}
